

const arabic = {
    Search:"بحث",
    Website:"الموقع",
    OpenModel:"فتح النموذج",
    Keyword:"كلمة مفتاحية",
    YourWebsiteLink:"رابط موقعك",
    GoToWriteAnArticle:"إذهب لكتابة مقال",
    MakeaComparison:"تحميل المرادفات",
    Unique:"حصرية",
    Coverage:"تغطية",
    Headings:"العناوين",
    Structure:"الكيان",
    Values:"القيم",
    Structures:"الكيانات",
    Unique:"مميز",
    Mutual:"مشترك",
    Missing:"مفقود",
}



const english = {
    Search:"Search",
    Website:"Website",
    OpenModel:"Open model",
    Keyword:"Keyword",
    YourWebsiteLink:"Your website link",
    GoToWriteAnArticle:"Go to write an article",
    MakeaComparison:"Download synonyms",
    Unique:"Unique",
    Coverage:"Coverage",
    Headings:"Headings",
    Structure:"Structure",
    Values:"Values",
    Structures:"Structures",
    Unique:"Unique",
    Mutual:"Mutual",
    Missing:"Missing",

}


export const analyseContent = {
    "en":{
        Search:"Search",
        Website:"Website",
        OpenModel:"Open model",
        Keyword:"Keyword",
        YourWebsiteLink:"Your website link",
        GoToWriteAnArticle:"Go to write an article",
        MakeaComparison:"Download synonyms",
        UniqueH:"Unique",
        Coverage:"Coverage",
        Headings:"Headings",
        Structure:"Structure",
        Values:"Values",
        Structures:"Structures",
        Unique:"Unique",
        Mutual:"Mutual",
        Missing:"Missing",
        Add:"Add",
        Arabic:'Arabic',
        English:"English",
        Credit:"Request limit",
        //new
        Share:"Share",
        Articles:"Articles",
        Publishing:"Publishing",
        StructuringAnArticle :"Structuring an article",
        Save:"Save",
        WebSite:"Website",
        Country:"Country",
        Article:"Article",
        AiOrder:"System commands"


    
    },
    "ar":{
        AiOrder:"اوامر النضام",
        Article:"المقال",
        Search:"بحث",
        Website:"الموقع",
        OpenModel:"فتح النموذج",
        Keyword:"كلمة مفتاحية",
        YourWebsiteLink:"رابط موقعك",
        GoToWriteAnArticle:"إذهب لكتابة مقال",
        MakeaComparison:"تحميل المرادفات",
        UniqueH:"حصرية",
        Coverage:"تغطية",
        Headings:"العناوين",
        Structure:"الكيان",
        Values:"القيم",
        Structures:"الكيانات",
        Unique:"مميز",
        Mutual:"مشترك",
        Missing:"مفقود",
        Add:"اضافة",
        Arabic:"العربية",
        English:"الانجليزية",
        Credit:"عدد الركوستات",
        //
        Share:"مشاركه",
        Articles:"المقالات",
        Publishing:"النشر",
        StructuringAnArticle :"هيكلة مقال",
        Save:"حفظ",
        WebSite:"الموقع",
        Country:"البلد",
    }
}





export const ltrDirection = {direction:"ltr"}
export const rtlDirection = {direction: "rtl"}
export const LoginContent = {
    "en":{
        userName:"User name",
        password:"Password",
        login : "Sign in"
    },
    "ar":{
        userName:"اسم المستخدم",
        password:"كلمة السر",
        login : "تسجيل الدخول"
    },
    "none":{
        userName:"",
        password:"",
        login : ""
    },
}
export const ScreenScoreTran = {
    "en":{
        Website:"Website",
        name:"Name",
        score:"Score",
        Uniqueh:"Unique",
        Coverage:"Coverage",
        numberWord:"Word Number",
        numberHeader:"Head Number",
        number:"Number",
        Keywoard:"Keywoards",
        values:"Values",
        Unique:"Unique",
        Mutual:"Mutual",
        Missing:"Missing",
        perce:"Percentage of keyword"
    },
    "ar":{
        Website:"الموقع",
        name:"الاسم",
        score:"السكور",
        Uniqueh:"الحصرية",
        Coverage:"تغطية",
        numberWord:"عدد الكلمات",
        numberHeader:"عدد العناوين",
        number:"عدد",
        Keywoard:"كينات",
        values:"قيم",
        Unique:"مميز",
        Mutual:"مشترك",
        Missing:"مفقود",
        perce : "كثافة الكلمة المفتاحية"
    }
}


export const writeContet = {
    "en": {
      articles: "articles",
      save: "save",
      users: "users",
      structures: "structures",
      niche: "niche",
      content: "content",
      age: "age",
      score: "score",
      otherDetails: "other detail",
      analysis: "analysis",
      time: "time",
      institutions: "institutions",
      negativeFeelings: "negative feelings",
      positiveFeelings: "positive feelings",
      CommunicationWays: "Communication ways",
      explanation: "explanation",
      men: "men",
      solutions: "solutions",
      alerts: "alerts",
      encouragement: "encouragement",
      history: "history",
      woman: "woman",
      palces: "places",
      credibility: "credibility",
      TrustAndGuarantee: "Trust and guarantee",
      costs: "costs",
      Paragraph: "paragraph",
      Unique: "Unique",
      Coverage: "Coverage",
      Keyword: "Keyword",
      header: "Header",
      numberWord: "Word number",
      requestNumber: "Request number",
      takrar: "Recurrence rate",
      networks: "Networks",
      Synonyms: "Synonyms",
      Proposals: "Proposals",
      Top: "Top 10",
      Questions: "questions",
      Featured: "Featured",
      PrivateInformation: "Private Information",
      Titles: "Titles",
      KeyWords: "Keywords",
      InternalLinks: "Internal links",
      Sources: "Sources",
      Informations: "informations",
      TrackWords: "Track words",
      importantSynonyms: "important synonyms",
      used: "used",
      unused: "unused",
      ProposalsGoogle: "Suggestions from Google",
      TopInside:"The 10 most frequent words in the article",
      ListQuestions:"List of questions",
      Cancel:"Cancel",
      Comparison:"Comparison",
      Word:"Word",
      Repetition:"Repetition",
      AddWord:"Add Word"
    },
    "ar": {
      numberWord: "عدد الكلمات",
      articles: "المقالات",
      save: "حفظ",
      users: "المستخدمين",
      structures: "الكيانات",
      niche: "النش",
      content: "المحتوى",
      age: "العمر",
      score: "السكور",
      otherDetails: "تفاصيل اخرى",
      analysis: "تحليل",
      time: "وقت",
      institutions: "مؤسسات",
      negativeFeelings: "مشاعر سلبية",
      positiveFeelings: "مشاعر ايجابية",
      CommunicationWays: "طرق تواصل",
      explanation: "شرح",
      men: "رجال",
      solutions: "حلول",
      alerts: "تنبيهات",
      encouragement: "تحفيز",
      history: "تاريخ",
      woman: "امرأة",
      palces: "اماكن",
      credibility: "المصداقية",
      TrustAndGuarantee: "الثقة والضمان",
      costs: "التكاليف",
      Paragraph: "الفقرة",
      Unique: "حصرية",
      Coverage: "تغطية",
      header: "العنوان",
      Keyword: "الكلمة المفتاحية",
      requestNumber: "عدد الركوسات",
      takrar: "نسبة التكرار",
      networks: "الشبكات",
      Synonyms: "المردفات",
      Proposals: "المقترحات",
      ProposalsGoogle: "المقترحات من جوجل",
      Top: "أهم 10",
      TopInside:"اهم 10 كلمات متكررة في المقال",
      Questions: "الاسئلة",
      ListQuestions:"قائمه الاسئلة",
      Featured: "المميزة",
      PrivateInformation: "معلومات خاصة",
      Titles: "العناوين",
      KeyWords: "الكلمات المفتاحية",
      InternalLinks: "الروابط الداخلية",
      Sources: "المصادر",
      Informations: "المعلومات",
      TrackWords: "تتبع الكلمات",
      importantSynonyms: "اهم المردفات عن الكلمة",
      used: "المستخدمة",
      unused: "غير مستخدمة",
      Cancel:"عودة",
      Comparison:"المقارنه",
      Word:"الكلمة",
      Repetition:"التكرار",
      AddWord:"اضافة كلمة"
    }
  }




  export const ModalTrans = {
    "en": {
        NumberHeader:"Head Number",
        GptVesrion:"Chat-gpt Version",
        Cancel:"Cancel",
        Create:"Create",
        ChoseClassification:"Chose classification",
        Website:"Website",
        FeaturedImage:"Featured image",
        Title:"Title",
        Descreption:"Descreption",
        Save:"Save",
        Slug:"Slug",
        EnterSlug:"Enter Slug",
        Category:"Category",
        TagsCount:'Tags Count',
        Publishing:"Publishing",
        Edit:'Edit',
        NumberWord: "Number of word",
        Structure:"Structure",
        Values:"Values",
        Top10Key:"The most important 10 keywords",
        Google:"Google suggestions",
        Paragraphs:"Paragraphs",
        PrivateInformation:"Privatei nformation",
        Titles: "Titles",
        Questions:"Questions",
        Tracking:"Tracking",
        SystemCommands:'SystemCommand',
        Title:'Titles',
        Order:"Order"

    },
    "ar": {
        Title:'عنوان',
        Tracking:"تتبع",
        Titles: "العناوين",
        NumberHeader:"عدد العناوين",
        GptVersion:"نسخة المستخدمة",
        Cancel:"عودة",
        Create:"انشاء",
        ChoseClassification:"اختر التصنيف",
        Website:"الموقع",
        FeaturedImage:"الصورة البارزة",
        Title:"عنوان",
        Descreption:"الوصف",
        Save:"حفظ",
        Slug:"سلوج",
        EnterSlug:"ادخال سلوج",
        Category:"تصنيفات",
        TagsCount:'عدد التاجات',
        Publishing:"النشر",
        Edit:'تعديل',
        NumberWord: "عدد الكلمات",
        Structure:"الكينات",
        Values:"القيم",
        Top10Key:"اهم 10 كلمات مفتاحية",
        Google:"مقترخات جوجل",
        Paragraphs:"الفقرات",
        PrivateInformation:"المعلومات الخاصة",
        Questions:"الاسئلة",
        SystemCommands:'اوامر النضام',
        Order:"الامر"

    }
  }