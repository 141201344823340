import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { ModelSave } from "../../page/constants/index";

const TemplateModal = ({ show, handleClose, session, onSelectTemplate, onDelete }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setLoading(true);
      // Fetch templates when the modal is shown
      axios
        .get(ModelSave, {
          headers: {
            Authorization: `token ${session}`,
          },
        })
        .then((response) => {
          setTemplates(response.data);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Error fetching templates.");
          console.error("Error fetching templates:", error);
          setLoading(false);
        });
    }
  }, [show, session]);

  return (
    <>
      <Toaster />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className=" text-white"
        style={{ backgroundColor: "rgba(33, 36, 41, 0.6)", color: "white",zIndex:"999999999999999999999999" }}
      >
        <Modal.Header
          closeButton
          className="bg-dark text-white border-secondary"
          style={{ backgroundColor: "#212429", borderColor: "white" }}
        >
          <Modal.Title>قائمة النماذج</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="bg-dark text-white"
          style={{ backgroundColor: "#212429", color: "white" ,maxHeight:"450px",overflow:"auto"}}
        >
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" className="text-light" />
              <p>Loading...</p>
            </div>
          ) : templates.length > 0 ? (
            <Table
              striped
              bordered
              hover
              variant="dark"
              className="text-white"
              style={{ backgroundColor: "#212429", color: "white" }}
            >
              <thead>
                <tr>
                  <th>رقم النموذج</th>
                  <th>اسم النموذج</th>
                  <th>عدد الصفوف</th>
                  <th>اختر النموذج</th>
                </tr>
              </thead>
              <tbody>
                {templates.map((template, index) => (
                  <tr key={template.template_id}>
                    <td>{index + 1}</td>
                    <td>{template.template_name}</td>
                    <td>{template.row_count}</td>
                    <td style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                      <Button
                        variant="outline-light"
                        onClick={() => onSelectTemplate(template.template_id, template.template_name)}
                      >
                        اختر
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => onDelete(template.template_id)}
                      >
                        حذف
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">لا توجد نماذج متاحة.</p>
          )}
        </Modal.Body>
        <Modal.Footer
          className="bg-dark text-white border-secondary"
          style={{ backgroundColor: "#212429", borderColor: "white" }}
        >
          <Button variant="outline-light" onClick={handleClose}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(TemplateModal);
