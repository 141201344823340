import { useState,useEffect,lazy, Suspense } from 'react';
import { useNavigate  } from 'react-router-dom';
import './dashboard.css';
import axios from "axios";
import { setSession,setSerpApi } from "./action/index";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import {API_URL,auth_check} from "./constants/index"
const mapStateToProps = (state) => {

    return {
      userid: state.userid,
      session : state.session,
      url : state.url
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
     
      setSession: (id) => {
        dispatch( setSession(id)  );
      }
      ,
      setSerpApi: (api) => {
        dispatch(setSerpApi(api));
      }
    }
  }

const Analyse = lazy(() => import('./analyse'));
const Login = lazy(() => import('./login'));

const WebPage_Analyse = ({userid,session,setSerpApi}) => {
    const [authorized,Setauthorized] = useState()
    const [request_count,Setrequest_count] = useState()
    const [request_number,Setrequest_number] = useState()
        async function fetchData() {
            const url = auth_check;
            const headers = {
                        'Authorization': 'token '+session,
                      };
  
         
          try{
              const response = await axios.get(url,{headers});
    
              setSerpApi(response?.data?.serp_state)
              Setrequest_count(response.data.request_count)
              Setrequest_number(response.data.request_number)
              Setauthorized(true)
              
            }
          catch{
              console.log("try not authorized ")
              Setauthorized(false)
          }
        }

        useEffect(() => {
            fetchData();
        }, [session]);




     if(authorized == true){
     
        return(
            <Suspense fallback={<div>Loading...</div>}>
            <Analyse request_count={request_count} request_number={request_number}></Analyse>
            </Suspense>
        ) 
     }
     else{ 
        if(authorized == false){
        return(
            <Suspense fallback={<div>Loading...</div>}>
            <Login request_count={request_count} request_number={request_number}></Login>
            </Suspense>
            
        )}
        else{
            <div>loading ..</div>
        }
     }
   

  

}
export default connect(mapStateToProps, mapDispatchToProps)(WebPage_Analyse);