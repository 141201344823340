//export const API_URL = "http://127.0.0.1:8000/";
//export const API_URL = "https://scarpapi-production.up.railway.app/"
//export const API_URL =  "https://plankton-app-hfhma.ondigitalocean.app/"
export const API_URL= 'https://emulator.cell-seo.com/'
export const Extention_Analyse = ""
export const Extention = ''
export const AddCollection_Url =API_URL+'api/admin/collection/crieate/'
export const DeletCollection_Url =API_URL+'api/admin/collection/remove/collection/'
export const AddContentToCollection_Url = API_URL+'api/admin/collection/create/'
export const DeletInstace_Url = API_URL+"   api/admin/collection/remove/instance/"
export const DeletInstace_Url_Madjal = API_URL+ "api/filter/ElMdjal/curl/"
export const CurlInstace_Url_Mohtawa = API_URL+ "api/filter/mohtawa/curl/"
export const CurlInstace_Url_Nich = API_URL+ "api/filter/nich/curl/"
export const edit_Nich = API_URL+ "api/filter/nich/instance/"
export const edit_madjal = API_URL+ "api/filter/elmadjal/instance/"
export const CurlInstace_Url_WorldToDelet = API_URL+ "api/filter/wordltodelet/curl/"
export const Get_Account_By_id= API_URL+ "api/account/get"
export const Edit_Account_By_id= API_URL+"api/account/get/"
export const Password_edit= API_URL+"api/account/edit/"
export const Delet_Account =  API_URL+"api/registration/"
export const Delet_Age_instance = API_URL+"api/filter/age/"
export const Save_Age_instance = API_URL+"api/filter/age/add/"
export const auth_check = API_URL+"api/check/"
export const url_score_edit = API_URL+'api/filter/score/'
export const url_score_edit_paragraph = API_URL+'api/filter/score/paragraph'
export const geneateKeywoardGoogle = API_URL+'api/scrap/creat/cellseo/'
export const analyseCompareList = API_URL +'api/analyse/compare/'
export const analyseCompareMeanList = API_URL +'api/analyse/compare/mean/'
export const writeCompareList = API_URL +'api/write/compare/'
export const addExtraArticle = API_URL+ 'api/analyse/extra/'
export const snippte_useEffect = API_URL+'api/snippet/use'
export const CompareAnalyseDown = API_URL+'api/analyse/compare/down'
export const ScreenScore = API_URL+'api/scorescreen'
export const SaveWriten = API_URL+'api/write/'
export const Getartcl = API_URL+'api/cellseo/article/'
export const ShowArticleURl = API_URL+"article/"
export const TestThingsUrl = API_URL+"api/filter/test/"
export const Urlscore = API_URL+'api/score/';
export const DownloadKeywoardUrl = API_URL+'api/download/keywoard/';
export const Mo9trahatUrl = API_URL+"api/write/Related/"
export const MomayzWrite = API_URL+"api/full_data/momayz/"
export const snipet_data = API_URL+"api/snippet/data/"
export const publish_article = API_URL+'api/cellseo/publish/'
export const store_article = API_URL+'api/cellseo/store/'
export const Admin_strategy_url = API_URL+'api/cellseo/strategy/'
export const Get_Website_strategy_url = API_URL+'api/cellseo/strategy/website/'
export const Hearchy_endpoint = API_URL+'api/gpt/'
export const create_paragraph_endpoint = API_URL+'api/gpt/paragraph/'
export const generete_paragraph_endpoint = API_URL+'api/gpt/paragraph/generete/'
export const generete_meta_endpoint = API_URL+'api/gpt/meta/generete/'
export const generete_tag_endpoint = API_URL+'api/gpt/tags/generete/'
export const generete_chat_endpoint = API_URL+"api/gpt/chat/"
export const tracking_keywoard = API_URL+"api/tracking/"
export const SystemCommand = API_URL+"api/command/"
export const ColeredWordDelet = API_URL+"api/color/delet/"

export const ModelSave = API_URL+"api/model/"


