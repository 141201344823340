import React, { useState, useEffect } from "react";
import axios from "axios";
import { SaveWriten } from "../constants/index";
import "./showArtilce.css"
const ShowArticle = ({ id }) => {
  const [data, setData] = useState("");
  const callArticle = async () => {
    try {
      const urlResponseKey = await axios.get(SaveWriten + "?id=" + id);
      console.log(urlResponseKey.data);
      setData(urlResponseKey.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    document.title = "Emulator cell seo";

    callArticle();
  }, []);

  return (
    <div
      className="dashboard"
      style={{ display: "block", overflowY: "scroll" }}
    >
      <div class="table" style={{    display: "flex",width: "100%"}}>
        <div style={ {   border: "1px solid white"}}>
          <div >الكلمة المفتاحية</div>
          <div>{data.Keywoard}</div>
          </div>
        <div style={ {   border: "1px solid white"}}>
          <div>تكرار الكلمة المفتاحية</div>
          <div style={{padding:"3px"}}>{data.KeywoardNumber}%</div>
        </div>
        <div style={ {   border: "1px solid white"}}>
          <div>عدد كلمات المقال</div>
          <div>{data.WordNumber}</div>
        </div>
        
        <div style={ {   border: "1px solid white"}}>
          <div>السكور</div>
          <div>{data.scoreF}</div>
        </div>
        <div style={ {   border: "1px solid white"}}>
          <div>الحصرية</div>
          <div>{data.scoreH}</div>
        </div>
        <div style={ {   border: "1px solid white"}}>
          <div>التغطية</div>
          <div>{data.scoreT}</div>
        </div>
        <div style={ {   border: "1px solid white"}}>
          <div>الميتادسكربشن</div>
          <div>{data.meta}</div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data.content }} />
    </div>
  );
};
export default ShowArticle;
