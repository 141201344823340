import { useState, useEffect } from 'react';
import '../login.css';
import { connect } from "react-redux";
import { setLang } from "../action/index";

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    SetLang: (lang) => {
      dispatch(setLang(lang));
    }
  }
}

const ToggleLang = ({ lang, SetLang, type }) => {
  const [ToggleClass, setToggleClass] = useState("none");

  const ToggleClassOnchange = () => {
    if (lang === "ar") {
      SetLang("en");
      setToggleClass("toggleLang" + (type ? " inside" : ""));
    } else {
      SetLang("ar");
      setToggleClass("toggleLangToright" + (type ? " inside" : ""));
    }
  }

  useEffect(() => {
    if (lang === "en") {
      setToggleClass("toggleLang" + (type ? " inside" : ""));
    } else {
      setToggleClass("toggleLangToright" + (type ? " inside" : ""));
    }
  }, [lang, type]);

  return (
    <div className={ToggleClass} onClick={ToggleClassOnchange}>
      <div className="LeftToggle">Ar</div>
      <div className="RightToggle">En</div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleLang);
