import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App  from './App.js' ;
import "./styles.scss";
import { GeneralContextProvider } from "./page/context/context";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Provider } from 'react-redux';
import store from './page/reducer/index';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <GeneralContextProvider>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </GeneralContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
