import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './login.css';
import axios from "axios";
import { setSession,setSerpApi } from "./action/index";
import { connect } from "react-redux";
import { API_URL } from "./constants/index";
import ToggleLang from "./Composant/toggleLang";
import { LoginContent, ltrDirection, rtlDirection } from "./constants/translation";

const mapStateToProps = (state) => {
  return {
    lang: state.lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: (id) => {
      dispatch(setSession(id));
    }
    ,
    setSerpApi: (api) => {
      dispatch(setSerpApi(api));
    }
  };
};

const Login = ({ setSession, lang, setSerpApi }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [showProg, setProg] = useState(false);
  const [content, setContent] = useState(LoginContent.none);

  const url = API_URL;

  const handleLogin = async () => {
    setProg(true);

    const url_login = url + 'api/login/';
    const data_full = { 'username': username, "password": password };
    try {
      const response = await axios.post(url_login, data_full);
      setSession(response.data["token"]);

      let url_check = url + 'api/check/';
      const headers = {
        'Authorization': 'token ' + response.data["token"],
      };

      const responseCheck = await axios.get(url_check, { headers });
      setProg(false);
      console.log("responseCheck:",responseCheck?.data?.serp_state)
      setSerpApi(responseCheck?.data?.serp_state)
    
      if (responseCheck.data.admin === true) {
        navigate('/dashboard');
      } else {
        navigate('/analyse');
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      setProg(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const redirect = params.get('redirect');
    if (token) {
      console.log("true")
      setSession(token);
      console.log(redirect)
      if(redirect === "analyse"){
        navigate('/analyse');
      }
      else{
        navigate('/write/undifined');
      }
      
    }
    setContent(LoginContent[lang]);
  }, [lang, location.search, setSession]);

  return (
    <div className="login" style={lang === "en" ? ltrDirection : rtlDirection}>
      <ToggleLang />
      <div className='login-div'>
        <img src="/logo.png" alt="" />
        <input
          id="userid"
          type="text"
          onChange={(e) => setUser(e.target.value)}
          placeholder={content.userName}
        />
        <input
          id="passwordid"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder={content.password}
        />
        <button className='login-button' onClick={handleLogin}>{content.login}</button>
        <div>
          <div className={showProg ? "loader-small" : ""}></div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);