import { useState,useEffect,lazy, Suspense } from 'react';
import { useNavigate  } from 'react-router-dom';
import './dashboard.css';
import axios from "axios";
import { setSession } from "./action/index";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import {API_URL,auth_check} from "./constants/index"
const mapStateToProps = (state) => {

    return {
      userid: state.userid,
      session : state.session,
      url : state.url
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
     
      setSession: (id) => {
        dispatch( setSession(id)  );
      }
    }
  }

const Dashboard = lazy(() => import('./dashboard-admin'));

const WebPage_Dashboard = ({setSession,userid,session}) => {
    const [authorized,Setauthorized] = useState()
    
        async function fetchData() {
            const url = auth_check;
            const headers = {
                        'Authorization': 'token '+session,
                      };
  
         
          try{
              const response = await axios.get(url,{headers});
              Setauthorized(true)
              
            }
          catch{
              console.log("try not authorized ")
              Setauthorized(false)
          }
        }

        useEffect(() => {
            fetchData();
        }, [session]);




     if(authorized == true){
     
        return(
            <Suspense fallback={<div>Loading...</div>}>
            <Dashboard ></Dashboard>
            </Suspense>
        ) 
     }
     else{ 
        if(authorized == false){
        return(
            <div>
            </div>
        )}
        else{
            <div>loading ..</div>
        }
     }
   

  

}
export default connect(mapStateToProps, mapDispatchToProps)(WebPage_Dashboard);