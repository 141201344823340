import React from "react";
import './cercleScoreSmall.scss'
import { useState,useEffect } from "react";
function calculer(one,two,three,four,moyen,padding){ 
  if(one === NaN){
    one = 0
  }
  if(moyen === NaN){
    moyen = 0
  }
//moyen[0]-((one*moyen[0])/100),moyen[1]((two*moyen[1])/100),moyen[2]-((three*moyen[2])/100),
 
    let scoreOne = moyen[0]-((one*122/100))
    let scoreTwo = moyen[1]-((two*173/100))
    let scoreThree = moyen[2]-((three*234)/100)
    let scoreFour = moyen[3]-((four*298)/100)
    
    /*if(scoreOne < padding[0]){
        scoreOne = padding[0]
    }
    if(scoreTwo < padding[1]){
        scoreTwo = padding[1]
    }
    if(scoreThree < padding[2]){
        scoreThree = padding[2]
    }
    if(scoreFour < padding[3]){
        scoreFour = padding[3]
    }
    //---------------------
   
    if(scoreOne > padding[0] && one >50){
        scoreOne = scoreOne + (padding[0]/one)
    }
    
    if(scoreTwo < padding[1] && two >50){
        scoreTwo = scoreTwo + (padding[1]/two)
    }
    if(scoreThree < padding[2] && three >50){
        scoreThree = scoreThree + (padding[2]/three)
    }
    if(scoreFour < padding[3] && four  >50){
        scoreFour = scoreFour + (padding[3]/four)
    }*/

    return [scoreOne,scoreTwo,scoreThree,scoreFour]
}
function CercleScore({ global,one,two,three,four,type }) {
   
    const moyen =[200,251,310,364]
    //const padding =  [40,36,38,38]
    const padding =  [78,79,76,66]
    const [animationStyles4, setAnimationStyles4] = useState({  
        strokeDashoffset: 0, // Initial value, adjust as needed
      });
    const [animationStyles3, setAnimationStyles3] = useState({
        strokeDashoffset: 0, // Initial value, adjust as needed
      });
      const [animationStyles2, setAnimationStyles2] = useState({
        strokeDashoffset: 0, // Initial value, adjust as needed
      });
    const [animationStyles1, setAnimationStyles1] = useState({
        strokeDashoffset: 0, // Initial value, adjust as needed
      });
     
      useEffect(() => {
        let finalMoyen = calculer(one, two, three, four, moyen,padding);
        
        setAnimationStyles1({
          strokeDashoffset: finalMoyen[0],
        });
        setAnimationStyles2({
          strokeDashoffset: finalMoyen[1],
        });
        setAnimationStyles3({
          strokeDashoffset: finalMoyen[2],
        });
        setAnimationStyles4({
          strokeDashoffset: finalMoyen[3],
        });
      }, [one, two, three, four]);
    return (
        <div className="container">

	<div className="container__progressbars">
  
		<div className="progressbar" >
      
			<svg className="progressbar__svg">
				<circle cx="55" cy="70" r="52" className="progressbar__svg-circle  shadow-4 four" style={{ //58
          strokeDasharray: 364, // Initial value, adjust as needed
          ...animationStyles4,
          transition: 'stroke-dashoffset 1s ease-in-out', // Apply transition for a smoother effect
        }}>
        </circle>
       
        <circle cx="55" cy="70" r="42" className="progressbar__svg-circle  shadow-3 three" style={{ //49
          strokeDasharray: 310, // Initial value, adjust as needed
          ...animationStyles3,
          transition: 'stroke-dashoffset 1s ease-in-out', // Apply transition for a smoother effect
        }}></circle>
        <circle cx="55" cy="70" r="32" className="progressbar__svg-circle  shadow-2  two" style={{ //40
          strokeDasharray: 251, // Initial value, adjust as needed
          ...animationStyles2,
          transition: 'stroke-dashoffset 1s ease-in-out', // Apply transition for a smoother effect
        }}></circle>
        <circle cx="55" cy="70" r="24" className="progressbar__svg-circle  shadow-1  one" style={{ //31
          strokeDasharray: 200, // Initial value, adjust as needed
          ...animationStyles1,
          transition: 'stroke-dashoffset 1s ease-in-out', // Apply transition for a smoother effect
        }}></circle>
            </svg>
            
            <span className="progressbar__text fourth ">{isNaN(four)?0:four}%</span>
            <span className="progressbar__text  three ">{isNaN(three)?0:three}%</span>
            <span className="progressbar__text  two ">{isNaN(two)?0:two}%</span>
            <span className="progressbar__text  one ">{isNaN(one)?0:one}%</span>
            <span className="progressbar__text  center ">{isNaN(global)?0:global}%</span>
            <div className ="typeCercle">{type}</div>
		</div>

		
	</div>

</div>
    );
}

export default CercleScore;