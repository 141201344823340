import {ProgressBar} from 'react-bootstrap';
import './rectangleScore.css'
function RectangleScore(props) {
    const calculerWidth = (initial) =>{
      return (initial*2.5)
    }
    return (
    <div className="scoreContainerFather">
      <div class="typeRectangle" >p</div>
    <div className='scoreContainer'>
      <ProgressBar className='progress-intial one' now={props.one}  >
        <div role="progressbar" className="progress-bar shadow-1" aria-valuenow={props.one} aria-valuemin={0} aria-valuemax={100} style={{width:calculerWidth(props.one),height:"10px"}}></div>
        <div className="score-bar">{props.one}%</div>

      </ProgressBar>
      <ProgressBar className='progress-intial four' now={props.four}  >
        <div role="progressbar" className="progress-bar shadow-2" aria-valuenow={props.four} aria-valuemin={0} aria-valuemax={100} style={{width:calculerWidth(props.four),height:"10px"}}></div>
        <div className="score-bar">{props.four}%</div>

      </ProgressBar>

      <ProgressBar className='progress-intial two' now={props.two} >
        <div role="progressbar" className="progress-bar shadow-3" aria-valuenow={props.two} aria-valuemin={0} aria-valuemax={100} style={{width:calculerWidth(props.two),height:"10px"}}></div>
        <div className="score-bar">{props.two}%</div>

      </ProgressBar>

      <ProgressBar className='progress-intial three' now={props.three} >
        <div role="progressbar" className="progress-bar shadow-4" aria-valuenow={props.three} aria-valuemin={0} aria-valuemax={100} style={{width:calculerWidth(props.three),height:"10px"}}></div>
        <div className="score-bar">{props.three}%</div>

      </ProgressBar>
      
    </div>
    </div>
  );
}

export default RectangleScore;